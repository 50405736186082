import type { NextComponentType, NextPageContext } from "next";
import type { IUserProfileInput, LayoutInternalProps, LayoutProps, PageProps } from "@types";

import React, { FC, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Header from "@modules/layout/header";
import Footer from "@modules/layout/footer";
import cn from "classnames";
import { ToastContainer } from "react-toastify";
import { useRouter } from "next/router";
import { useAppContext } from "@context/app";
import { commonApi, userApi } from "gate";
import { useAcceptCookies } from "@hooks/useAcceptCookies";
import Loading from "assets/lottie/loading";
import { getCookie } from "@utils/Cookies";
import { COOKIE_AREA_KEY, COOKIE_COUNTRY_KEY, COOKIE_IGNORE_PHONE_VERIFICATION } from "@utils/constants";
import { hasArea } from "@utils/checkDependencies";
import useCart from "@hooks/useCart";
import { setExternalUserId } from "@utils/oneSignal";
import { setSegmentIdentifyPhoneNumber, segmentGetAnonymousId, segmentIdentify } from "@utils/segment";
const CookieBanner = dynamic(() => import("@modules/cookie-banner"), { ssr: false });
const NProgress = dynamic(() => import("@elements/nprogress"), { ssr: false });
const ModalUI = dynamic(() => import("./modal-ui"), { ssr: false });
const AndroidSmartAppBanner = dynamic(() => import("@elements/AndroidSmartAppBanner"), { ssr: false });

import getConfig from "next/config";
import useBroadcastChannel from "@hooks/useBroadcastChannel";
import { useUI } from "@context/ui-managed-context";
const { publicRuntimeConfig } = getConfig();

const Layout: FC<LayoutInternalProps> = ({
  children,
  mainClassName,
  rootClassName,
  mainContainerClassName,
  contentContainerClassName,
  pageProps
}) => {
  const { locale, pathname } = useRouter();
  const {
    country,
    setUserCurrency,
    setUserDecimal,
    setUserWalletBalance,
    user,
    setUserCurrencyIso,
    area,
    setSyncData,
    syncData
  } = useAppContext();
  const { updateCart } = useCart()
  const Cookies = getCookie();
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies();
  const [isBanner, setIsBanner] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const { setModalView, openModal } = useUI();

  useEffect(() => {
    if (publicRuntimeConfig.ONESIGNAL_TOKEN && window.OneSignal) {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function () {
        window.OneSignal.on("notificationDisplay", function (event: any) {
          if (event?.data?.type === "OFFER") {
            if (hasArea()) {
              commonApi
                .getSync()
                .fetch()
                .then((res) => {
                  setSyncData(res.data);
                })
                .catch((err) => { });
            }
          }
        });
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window !== "undefined" && window.OneSignal]);

  useEffect(() => {
    if (user.isLoggedIn && syncData) {
      setUserWalletBalance(syncData.user?.wallet || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn, syncData]);


  useEffect(() => {
    if (user.isLoggedIn && syncData && pathname === "/") {
      if (!Cookies.get(COOKIE_IGNORE_PHONE_VERIFICATION) && syncData.phone_verification.mode !== "none" && syncData.user && !syncData.user?.phone) {
        userApi.suggestPhone().fetch().then(res => {
          if (res.data.phone) {
            setModalView("VERIFY_PHONE_NUMBER_NOTICE_WITH_PHONE_SUGGESTION", false, res.data.phone);
            openModal();
          } else {
            setModalView("VERIFY_PHONE_NUMBER_NOTICE", false);
            openModal();
          }
        }).catch(err => {
          setModalView("VERIFY_PHONE_NUMBER_NOTICE", false);
          openModal();
        })
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn, syncData, pathname]);



  useEffect(() => {
    if (pageProps.appFallback) {
      setSyncData(pageProps.appFallback)
      identifyUser(pageProps.appFallback.user)
    }
    else {
      if (hasArea()) {
        commonApi.getSync().fetch().then((res => {
          setSyncData(res.data)
          identifyUser(res.data.user)
        })).catch(err => { })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps.appFallback])

  useEffect(() => {
    let userCurrency;
    if (country) {
      if (locale?.includes("ar-")) {
        userCurrency = country.currency_ar;
      } else {
        userCurrency = country.currency_en;
      }
    } else {
      userCurrency = "KD";
    }
    const userCurrencyIso = country ? country.currency_iso : "kwd";
    const userDecimal = country?.decimals || 2;
    setUserCurrency(userCurrency);
    setUserCurrencyIso(userCurrencyIso);
    setUserDecimal(userDecimal);
    updateCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, locale]);



  const handleMessage = (event: any) => {
    if (event === "syncData" && hasArea()) {
      commonApi.getSync().fetch().then((res => {
        setSyncData(res.data)
      })).catch(err => { })
    }
  };
  useBroadcastChannel('dabdoob_chanel', handleMessage);

  const identifyUser = (user?: IUserProfileInput) => {
    setTimeout(() => {
      const anonymousId = segmentGetAnonymousId()
      if (anonymousId) {
        if (user) {
          setExternalUserId(`${user.id}`, user.os_id_hash)
          setSegmentIdentifyPhoneNumber(user, anonymousId)
          // segmentIdentify(user.id, { email: user.email, userId: user.id, name: user.name, $onesignal_user_id: user.id, anonymousId: anonymousId })
        } else {
          setExternalUserId(anonymousId)
          segmentIdentify(undefined, { $onesignal_user_id: anonymousId, anonymousId: anonymousId })
        }
      }
    }, 3000);
  }


  const isLoading = (Cookies.get(COOKIE_COUNTRY_KEY) && Cookies.get(COOKIE_AREA_KEY)) && (!country || !area || !syncData) && !isOpenConfirm ? true : false
  return (
    <div
      className={cn("min-h-[100dvh] height-screen flex flex-col bg-white relative", locale?.includes("ar-") ? "!font-arabic" : "!font-arabic", rootClassName)}
      dir={locale?.includes("ar-") ? "rtl" : "ltr"}
      id="dabdoob-defaultLayout"
    >
      {isLoading && <Loading isCenter />}
      <ToastContainer autoClose={3000} />
      <AndroidSmartAppBanner setIsBanner={(e) => setIsBanner(e)} />
      <Header menu={syncData?.website?.menu?.items || []} isBanner={isBanner} setIsOpenConfirm={(v: boolean) => setIsOpenConfirm(v)} />
      <div
        className={cn("flex flex-col w-full flex-1 ", mainContainerClassName, isBanner ? "sm:mt-[190px] xs:mt-[140px] mt-[140px]" : "sm:mt-[140px] xs:mt-[80px] mt-[80px]"
        )}
      >
        <main className={cn("flex flex-1", mainClassName)}>
          <div className={cn("flex flex-col w-full relative ", contentContainerClassName)}>
            {children}
          </div>
        </main>
        <Footer countries={syncData?.countries || []} area={syncData?.area} />
      </div>
      <ModalUI />
      <NProgress />
      <CookieBanner
        title="This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy."
        hide={acceptedCookies}
        onAccept={() => onAcceptCookies()}
      />
    </div>
  );
};

export default Layout;

export const getLayout = (
  Page: NextComponentType<NextPageContext, any, PageProps>,
  pageProps: PageProps,
  props?: LayoutProps
) => (
  <Layout {...props} pageProps={pageProps}>
    <Page {...pageProps} />
  </Layout>
);

export const noLayout = (
  Page: NextComponentType<NextPageContext, any, PageProps>,
  pageProps: PageProps
) => <Page {...pageProps} />;
